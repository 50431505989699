<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
	<div style="height: 100%">
		<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
			<a-col :span="24" class="mb-24">
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ height: '100%' }">
					<a-col :span="6" style="height: 100%">
						<div class="dialog_box">
							<div class="list">
								<a-collapse v-model="roleActiveKey">
									<a-collapse-panel :header="item.title" v-for="item in roleData" :key="item.id">
										<div class="item" :id="'role_item_' + list.id" :class="{ active: list.id == roleActiveId }" v-for="list in item.list" :key="'role_' + list.id" @click="roleItem(list.id)">
											<div class="line1 c-line-1">{{ list.title }}</div>
											<div class="line2 c-line-2">{{ list.desc }}</div>
										</div>
									</a-collapse-panel>
								</a-collapse>
							</div>
						</div>
					</a-col>
					<a-col :span="18" style="height: 100%">
						<div class="content_box">
							<div class="output" id="output">
								<div class="list_box" :class="{ active: dItem.id == roleActiveId }" v-if="dItem.id == roleActiveId" v-for="dItem in roleDataItem()" :key="'rlist_' + dItem.id">
									<div class="list" v-for="(item, index) in dItem.list" :key="'msg_' + item.id">
										<div class="item right" v-if="item.message">
											<div class="message">
												<v-md-editor v-model="item.message" mode="preview"></v-md-editor>
											</div>
											<div class="avatar">
												<img :src="userInfo.avatar" alt="" />
											</div>
										</div>
										<div class="item left" v-if="item.response">
											<div class="avatar">
												<img src="@/assets/imgs/logo.png" alt="" />
											</div>
											<div class="message">
												<v-md-editor v-model="item.response" mode="preview"></v-md-editor>
												<div class="tools" v-if="!item.transmit">
													<div class="copy" @click="messageCopy(item.response)">
														<a-icon type="copy" />
														<span>复制</span>
													</div>
													<!-- <div class="collect" @click="messageCollect(item.id)">
														<a-icon type="heart" />
														<span>收藏</span>
													</div> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="input">
								<textarea class="textarea" v-model="inputText" placeholder="请输入内容（Enter 换行）"></textarea>
								<div class="button">
									<a-button type="primary" size="small" :disabled="!disabled" @click="sendMessage"> 发送 </a-button>
								</div>
							</div>
						</div>
					</a-col>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: {},
		data() {
			return {
				inputText: "", // 发送数据
				disabled: false, // 按钮状态
				dialogList: [], // 会话列表
				dialogId: 0, // 当前会话id
				createModal: false, // 创建会话标题弹窗
				createLoading: false, // 异步加载状态
				createTitle: "", // 新建会话标题
				modalType: "create", // create / edit
				editId: "", // 编辑标题ID

				roleData: [],
				roleActiveKey: [],
				roleActiveId: 0,
				roleFirstId: ""
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"])
		},
		watch: {
			inputText(newValue, oldValue) {
				this.disabled = newValue == "" ? false : true
			}
		},
		created() {
			this.getRoleList()
		},
		mounted() {},
		methods: {
			// 合并角色数据
			roleDataItem() {
				let arr = []
				this.roleData.map(item => {
					item.list.map(list => {
						arr.push(list)
					})
				})
				return arr
			},
			// 切换角色类型
			roleItem(id) {
				this.roleActiveId = id
				this.roleData.map(item => {
					item.list.map(list => {
						if (list.id == id) {
							if (!list.transmit && !list.list.length) this.getRoleHistory()
						}
					})
				})
			},
			// 获取角色列表
			getRoleList() {
				this.$http("role.second").then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							let obj = {}
							res.data.forEach(item => {
								const key = item.topic_id
								const value = {
									list: [],
									transmit: false,
									...item
								}
								if (!obj[key]) {
									obj[key] = [value]
								} else {
									obj[key].push(value)
								}

								if (item.id == this.$route.query.id) {
									this.roleFirstId = item.topic_id
								}
							})

							let arr = []
							Object.keys(obj).forEach(key => {
								arr.push({
									id: key,
									title: obj[key][0].topic_name,
									list: obj[key]
								})
								this.roleActiveKey.push(key)
							})
							if (this.$route.query.id) {
								this.roleActiveId = this.$route.query.id
							} else {
								this.roleActiveId = arr[0].list[0].id
							}
							this.getRoleHistory()
							this.roleData = arr
							this.$nextTick(() => {
								document.getElementById(`role_item_` + this.roleActiveId).scrollIntoView({ behavior: "smooth", block: "center" })
							})
						}
					}
				})
			},
			// 历史记录
			getRoleHistory() {
				this.$http("role.history", { prompt_id: this.roleActiveId }).then(res => {
					if (res.code === 1) {
						this.roleData.map(item => {
							item.list.map(list => {
								if (list.id == this.roleActiveId) {
									list.list = res.data.reverse()
								}
							})
						})

						this.createRoleChat()
					}
				})
			},
			// 创建角色会话
			createRoleChat() {
				this.$http("role.create", { prompt_id: this.roleActiveId }).then(res => {
					if (res.code === 1) {
						this.roleData.map(item => {
							item.list.map(list => {
								if (list.id == this.roleActiveId) {
									list.group_id = res.data.group_id

									if (!list.list.length) {
										list.list.unshift({
											id: "welcome",
											message: "",
											response: res.data.prompt.welcome,
											transmit: false
										})
									}
								}
							})
						})
					}
				})
			},
			// 信息复制
			async messageCopy(text) {
				try {
					await navigator.clipboard.writeText(text)
					this.$message.success("已复制到剪切板")
				} catch (err) {
					this.$message.error("复制失败")
				}
			},
			// 信息收藏
			messageCollect(id) {
				console.log(id, "收藏ID")
			},
			// 发送内容
			sendMessage() {
				this.roleData.map(item => {
					item.list.map(list => {
						if (list.id == this.roleActiveId) {
							if (list.transmit) {
								this.$message.warning("请等待当前对话完成...")
							} else {
								if (!this.inputText) {
									console.log("输入为空")
									return
								}
								this.fetchDataStream(this.inputText, this.roleActiveId, list.group_id)
							}
						}
					})
				})
			},
			// 发送请求
			async fetchDataStream(message, dId, gId) {
				if (!message) {
					console.log("输入为空")
					return
				}

				this.inputText = ""
				this.roleData.map(item => {
					item.list.map(list => {
						if (list.id == dId) {
							list.list.unshift({
								id: "message",
								message,
								response: "",
								transmit: true
							})
						}
					})
				})

				this.disabled = true
				const postData = { type: "rule", message, group_id: gId, prompt_id: dId },
					url = this.$BASE_API + "/addons/chatgpt/web/sendText",
					controller = new AbortController(),
					Token = this.token,
					Sign = window.location.search.replace(/\?/g, "")

				try {
					const response = await fetch(url, {
						method: "post",
						headers: {
							"Content-Type": "application/json;charset=utf-8",
							Token,
							Sign
						},
						body: JSON.stringify(postData),
						signal: controller.signal
					})

					const reader = response.body.getReader()
					let data = ""

					while (true) {
						const { done, value } = await reader.read(),
							str = new TextDecoder().decode(value)

						if (str.indexOf("data: [DONE]") != -1 || str.indexOf("data:[DONE]") != -1 || done) {
							const id = str.replaceAll(" ", "").split("data:[DONE]")[1]

							this.roleData.map(item => {
								item.list.map(list => {
									if (list.id == dId) {
										list.transmit = false
										list.list[0].transmit = null
										list.list[0].id = id
									}
								})
							})
							break
						}

						data += str
						this.roleData.map(item => {
							item.list.map(list => {
								if (list.id == dId) {
									list.list[0].response = data
								}
							})
						})
					}
				} catch {
					console.error("请求失败")
				}
			},
			// 监听按键 Ctrl + Enter 换行 (弃用)
			handleKeyCode(event) {
				if (event.keyCode == 13) {
					if (!event.ctrlKey) {
						event.preventDefault()
						this.inputText && this.sendMessage()
					} else {
						this.inputText && (this.inputText = this.inputText + "\n")
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .ant-collapse {
		border: none;
		background: transparent;
		.ant-collapse-item {
			border: none;
			.ant-collapse-header {
				padding: 12px 16px;

				.ant-collapse-arrow {
					left: 0;
				}
			}
			.ant-collapse-content {
				border: none;
				background: transparent;

				.ant-collapse-content-box {
					padding: 0;
				}
			}
		}
	}

	.dialog_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: #f7f7f7;
		padding: 12px 0;

		.list {
			flex: 1;
			height: 100%;
			padding: 12px;
			overflow: hidden;
			padding-top: 0;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			// 滚动条两端按钮
			&::-webkit-scrollbar-button {
				height: 10px;
			}

			.item {
				padding: 12px;
				background: #fff;
				border-radius: 10px;
				margin-bottom: 12px;
				cursor: pointer;
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}

				&.active {
					border: 1px solid #1890ff;
					box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
				}

				.line1 {
					color: #000;
					font-weight: 700;
				}

				.line2 {
					margin-top: 4px;
					color: #999;
				}
			}
		}
	}
	.content_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;

		// 滚动条整体
		::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}
		// 滚动条滑块
		::-webkit-scrollbar-thumb {
			background-clip: padding-box;
			background-color: #a5adb7;
			border: 1px dashed rgba(0, 0, 0, 0);
			border-radius: 6px;
		}
		// 滚动条滑块 hover
		::-webkit-scrollbar-thumb:hover {
			background: #4a4a4a;
		}
		// 滚动条轨道
		::-webkit-scrollbar-track {
			background-color: transparent;
		}
		// 滚动条两端按钮
		::-webkit-scrollbar-button {
			height: 10px;
		}

		.output {
			flex: 1;
			border-radius: 12px;
			background: #f7f7f7;
			overflow: hidden;
			display: flex;
			flex-direction: column;

			.list_box {
				overflow-y: auto;
				overflow-x: hidden;
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				z-index: 1000;
				padding: 20px;
				margin: 0 6px;

				.list {
					margin-bottom: 20px;

					.item {
						margin-bottom: 20px;
						display: flex;
						flex-wrap: nowrap;
						$imgsize: 40px;

						.avatar {
							width: $imgsize;
							height: $imgsize;
							border-radius: 50%;
							overflow: hidden;
							border: 2px solid #fff;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.message {
							max-width: calc(100% - #{$imgsize + 20px} * 2);
							padding: 10px 12px;

							::v-deep .v-md-editor__right-area {
								width: auto;
								min-width: 0;
							}

							::v-deep .vuepress-markdown-body {
								padding: 0;
							}
						}

						&.left {
							justify-content: flex-start;

							.avatar {
								margin-right: 20px;
							}

							.message {
								border-radius: 0 12px 12px 12px;
								background: #fff;
								color: #000;
								position: relative;

								&:hover {
									.tools {
										display: flex;
									}
								}

								.tools {
									position: absolute;
									bottom: 0;
									left: 0;
									transform: translateY(100%);
									padding-top: 10px;
									display: flex;
									align-items: center;
									display: none;

									.copy,
									.collect {
										padding: 4px 10px;
										border: 1px solid #eee;
										border-radius: 6px;
										cursor: pointer;

										&:hover {
											box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
											background: #fff;
										}

										span {
											margin-left: 4px;
										}
									}

									.collect {
										margin-left: 12px;
									}
								}
							}
						}

						&.right {
							justify-content: flex-end;

							.avatar {
								margin-left: 20px;
							}

							.message {
								border-radius: 12px 0 12px 12px;
								background: $message_color;
								color: #fff;
								::v-deep .vuepress-markdown-body {
									background: $message_color;
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
		.input {
			margin-top: 20px;
			height: 120px;
			position: relative;
			background: #f7f7f7;
			border-radius: 12px;
			padding: 12px 84px 12px 12px;

			.textarea {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
				resize: none;
				background: transparent;
				padding: 0;
				margin: 0;
			}

			.button {
				position: absolute;
				bottom: 12px;
				right: 12px;
			}
		}
	}
</style>
